import React from "react";
import { Link } from "gatsby";

const Item = (label, link, partiallyActive) => (
  <li
    className="nav-item u-header__nav-item"
    data-event="hover"
    data-animation-in="fadeInUp"
    data-animation-out="fadeOut"
  >
    <Link
      to={link}
      className="nav-link u-header__nav-link"
      activeClassName="active"
      partiallyActive={partiallyActive}
    >
      {label}
    </Link>
  </li>
);

const Menu = () => (
  <div
    id="navBar"
    className="collapse navbar-collapse u-header__navbar-collapse py-0"
  >
    <ul className="navbar-nav u-header__navbar-nav">
      {Item("Home", "/", false)}
      {Item("Products", "/products/", true)}
      {Item("Services", "/services/", true)}
      {Item("Projects", "/projects/", true)}
      {Item("News", "/news/", true)}
      {Item("Careers", "/careers/", true)}
      {Item("About Us", "/about-us/", false)}
      <li
        className="nav-item u-header__nav-item"
        data-event="hover"
        data-animation-in="fadeInUp"
        data-animation-out="fadeOut"
      >
        <Link
          to="/contact-us/"
          className="btn btn-outline-danger"
          activeClassName="active"
        >
          <span className="fa fa-comment-alt mr-1"></span>
          Contact Us
        </Link>
      </li>
    </ul>
  </div>
);

export default Menu;
