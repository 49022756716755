import React, { Component } from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const Item = (label, link, icon) => (
  <li>
    <Link
      to={link}
      className="media align-items-center u-sidebar--panel__link py-2 px-5"
      activeClassName="active"
    >
      <span className={`fa ${icon} mr-2`}></span>
      <div className="media-body">
        <span>{label}</span>
      </div>
    </Link>
  </li>
);

export default class MenuMobile extends Component {
  constructor(props) {
    super(props);
    this.state = { animate: false };
  }

  render() {
    const { animate } = this.state;
    const { siteTitle, showMobileMenu, setShowMobileMenu } = this.props;
    let menuClass = "u-sidebar--left u-unfold--css-animation";
    menuClass += showMobileMenu ? " fadeInLeft" : " fadeOutLeft";

    if (!animate && !showMobileMenu) {
      menuClass += " u-unfold--hidden";
    }

    return (
      <StaticQuery
        query={graphql`
          query {
            file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                fixed(width: 200, height: 32) {
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
            }
          }
        `}
        render={data => (
          <aside
            id="sidebarContent"
            className={menuClass}
            style={{ animationDuration: "500ms" }}
            aria-labelledby="sidebarNavToggler"
            onAnimationEnd={() => {
              this.setState({ animate: false });
            }}
          >
            <div className="u-sidebar__scroller">
              <div className="u-sidebar__container">
                <div className="u-sidebar--panel__footer-offset">
                  <div className="d-flex align-items-center border-bottom pt-2 px-4">
                    <Link to="/" className="d-inline-block mr-3">
                      <Img
                        fixed={data.file.childImageSharp.fixed}
                        alt={siteTitle}
                        title={siteTitle}
                      />
                    </Link>

                    <button
                      type="button"
                      className="close u-sidebar__close ml-auto"
                      aria-controls="sidebarContent"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-unfold-event="click"
                      data-unfold-hide-on-scroll="false"
                      data-unfold-target="#sidebarContent"
                      data-unfold-type="css-animation"
                      data-unfold-animation-in="fadeInRight"
                      data-unfold-animation-out="fadeOutRight"
                      data-unfold-duration="500"
                      onClick={() => {
                        setShowMobileMenu(false);
                        this.setState({ animate: true });
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="js-scrollbar u-sidebar__body">
                    <div className="u-sidebar__content py-5">
                      <ul className="list-unstyled font-size-14 mb-5">
                        {Item("Home", "/", "fa-home")}
                        {Item("Products", "/products/", "fa-plug")}
                        {Item("Services", "/services/", "fa-tools")}
                        {Item("Projects", "/projects/", "fa-wrench")}
                        {Item("News", "/news/", "fa-newspaper")}
                        {Item("Careers", "/careers/", "fa-hard-hat")}
                        {Item("About Us", "/about-us/", "fa-building")}
                        {Item("Contact Us", "/contact-us/", "fa-comment-alt")}
                      </ul>
                    </div>
                  </div>
                </div>

                <footer className="u-sidebar__footer u-sidebar__footer--panel py-4 px-5">
                  <ul className="list-inline font-size-14 mb-0">
                    <li className="list-inline-item">
                      <OutboundLink
                        className="u-sidebar--panel__link pl-2"
                        href="https://www.facebook.com/staticpowerph/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit us on <i className="fab fa-facebook"></i> Facebook
                      </OutboundLink>
                    </li>
                  </ul>
                </footer>
              </div>
            </div>
          </aside>
        )}
      />
    );
  }
}
