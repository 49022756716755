import React, { Component } from "react";
import { animateScroll } from "react-scroll";

export default class ScrollTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transform: 0,
      visible: false
    };
  }
  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = event => {
    const scrollOffset = 200;
    if (window.scrollY > scrollOffset) {
      this.setState({ visible: true });
    } else {
      this.setState({ visible: false });
    }
  };

  scrollToTop = e => {
    e.preventDefault();
    animateScroll.scrollToTop();
  };

  render() {
    const { visible } = this.state;
    const className = visible ? "js-go-to u-go-to visible" : "js-go-to u-go-to";
    return (
      <a href="#top" className={className} onClick={this.handleScroll}>
        <span className="fa fa-arrow-up u-go-to__inner"></span>
      </a>
    );
  }
}
