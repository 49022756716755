import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import Menu from "./menu";

const Header = ({ siteTitle, setShowMobileMenu }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 200, height: 32) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
        mobile: file(relativePath: { eq: "logo-symbol.png" }) {
          childImageSharp {
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <header
        id="header"
        className="u-header u-header--modern u-header--bordered u-header--sticky-top-lg"
      >
        <div className="u-header__section">
          <div id="logoAndNav" className="container-fluid">
            {/* <!-- Nav --> */}
            <nav className="js-mega-menu navbar navbar-expand-lg u-header__navbar">
              {/* <!-- Logo --> */}
              <div className="u-header__navbar-brand-wrapper">
                <Link to="/" className="navbar-brand u-header__navbar-brand">
                  <span className="u-header__navbar-brand-default">
                    <Img
                      fixed={data.desktop.childImageSharp.fixed}
                      alt={siteTitle}
                      title={siteTitle}
                    />
                  </span>
                  <span className="u-header__navbar-brand-mobile mt-2">
                    <Img
                      fixed={data.mobile.childImageSharp.fixed}
                      alt={siteTitle}
                      title={siteTitle}
                    />
                  </span>
                </Link>
              </div>
              {/* <!-- End Logo --> */}

              {/* <!-- Responsive Toggle Button --> */}
              <button
                type="button"
                className="navbar-toggler btn u-hamburger u-header__hamburger"
                aria-label="Toggle navigation"
                aria-expanded="false"
                aria-controls="navBar"
                data-toggle="collapse"
                data-target="#navBar"
                onClick={() => setShowMobileMenu(true)}
              >
                <span className="d-none d-sm-inline-block">Menu</span>
                <span id="hamburgerTrigger" className="u-hamburger__box ml-3">
                  <span className="u-hamburger__inner"></span>
                </span>
              </button>
              {/* <!-- End Responsive Toggle Button --> */}

              {/* <!-- Navigation --> */}
              <Menu />

              <ul className="navbar-nav flex-row u-header__secondary-nav justify-content-flex-end">
                <li className="nav-item u-header__navbar-icon">
                  <Link
                    to="/contact-us/"
                    className="btn"
                    activeClassName="active"
                  >
                    <span className="fa fa-comment-alt mr-1"></span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    )}
  />
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
