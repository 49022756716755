import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const Footer = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "logo-symbol.png" }) {
          childImageSharp {
            fixed(width: 40, height: 40) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <footer className="bg-dark">
        <div className="container space-1">
          <div className="row justify-content-md-between">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 order-lg-1 mb-7 mb-lg-0">
              <h3 className="h6 text-white mb-3">Metro Manila Office</h3>
              <div className="list-group list-group-flush list-group-transparent">
                <p className="small text-muted">
                  <span className="fa fa-map-marker min-width-3 text-center mr-2"></span>
                  #5 General Lim St., San Antonio Village, Pasig City,
                  Philippines
                </p>
                <p className="small text-muted">
                  <span className="fa fa-phone min-width-3 text-center mr-2"></span>
                  (+632) 477 3408 / (+632) 697 5996
                </p>
                <p className="small text-muted">
                  <span className="fa fa-fax min-width-3 text-center mr-2"></span>
                  (+632) 477 2188
                </p>
                <p className="small text-muted">
                  <span className="fa fa-envelope min-width-3 text-center mr-2"></span>
                  <OutboundLink href="mailto:info@static.ph">
                    info@static.ph
                  </OutboundLink>
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 order-lg-2 mb-7 mb-lg-0">
              <h3 className="h6 text-white mb-3">Cebu Office</h3>
              <div className="list-group list-group-flush list-group-transparent">
                <p className="small text-muted">
                  <span className="fa fa-map-marker min-width-3 text-center mr-2"></span>
                  2B 2nd Floor, M and J Ortiz Bldg V Rama Ave, Guadalupe Cebu
                  City
                </p>
                <p className="small text-muted">
                  <span className="fa fa-phone min-width-3 text-center mr-2"></span>
                  (+6332) 232 0556
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-2 order-lg-3 mb-7 mb-lg-0">
              <h3 className="h6 text-white mb-3">About</h3>

              <div className="list-group list-group-flush list-group-transparent">
                <Link
                  to="/products/"
                  className="list-group-item list-group-item-action"
                >
                  Products
                </Link>
                <Link
                  to="/services/"
                  className="list-group-item list-group-item-action"
                >
                  Services
                </Link>
                <Link
                  to="/projects/"
                  className="list-group-item list-group-item-action"
                >
                  Projects
                </Link>
                <Link
                  to="/about-us/"
                  className="list-group-item list-group-item-action"
                >
                  About Us
                </Link>
              </div>
            </div>

            <div className="col-6 col-md-3 col-lg-2 order-lg-4 mb-7 mb-lg-0">
              <h3 className="h6 text-white mb-3">Company</h3>

              <div className="list-group list-group-flush list-group-transparent">
                <Link
                  to="/news/"
                  className="list-group-item list-group-item-action"
                >
                  News
                </Link>
                <Link
                  to="/careers/"
                  className="list-group-item list-group-item-action"
                >
                  Careers
                </Link>
                <Link
                  to="/contact-us/"
                  className="list-group-item list-group-item-action"
                >
                  Contact Us
                </Link>
                <OutboundLink
                  href="https://www.facebook.com/staticpowerph/"
                  className="list-group-item list-group-item-action"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </OutboundLink>
              </div>
            </div>

            <div className="col-12 col-md-3 col-lg-2 order-lg-4 mb-7 mb-lg-0">
              <div className="d-flex align-items-center flex-column">
                <Link to="/" className="d-inline-block mr-3">
                  <Img
                    style={{ width: "40px", maxWidth: "100%" }}
                    fixed={data.file.childImageSharp.fixed}
                    alt={siteTitle}
                    title={siteTitle}
                  />
                </Link>

                <div className="btn-group d-block mb-1 mb-lg-auto text-center">
                  <p className="small text-muted">
                    &copy; Copyright 2019
                    <br />
                    <a href="https://www.static.ph">Static Power Philippines</a>
                    <br />
                    <span className="small text-muted">
                      Made by{" "}
                      <OutboundLink
                        href="https://www.kdcsoftware.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        KDC Software
                      </OutboundLink>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )}
  />
);

export default Footer;
