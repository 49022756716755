import React, { Fragment, useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

import Header from "./header";
import Footer from "./footer";
import MenuMobile from "./menu-mobile";
import ScrollTop from "./scrollTop";

import "../scss/theme.scss";
import "../styles/font-awesome.min.css";
import "../styles/animate.min.css";

const Layout = ({ children }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <Fragment>
          <Header
            siteTitle={data.site.siteMetadata.title}
            setShowMobileMenu={show => setShowMobileMenu(show)}
          />
          <MenuMobile
            siteTitle={data.site.siteMetadata.title}
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={show => setShowMobileMenu(show)}
          />
          <main id="content" role="main">
            {children}
          </main>
          <Footer siteTitle={data.site.siteMetadata.title} />
          <ScrollTop />
        </Fragment>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
